.circle-form {
  display: flex;
  flex-direction: column;
  padding: 60px 20px 80px 20px;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: relative;
}
.jsonForm-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.joiningCircle {
  color: #8f92a1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.MuiGrid-item {
  margin-bottom: 20px !important;
}
.fixed-button {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.circle-form-content {
  height: 80vh;
  overflow-y: scroll;
}
.circle-btn {
  width: 90%;
  padding: 16px 50px;
  background: #40ad52;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.08px;
  color: #ffffff;
  border-style: none;
  margin-bottom: 30px;
}
.selected-field {
  transform: scale(1.2); 
  transition: transform 0.3s ease-in-out;
}