.TravelInvalidID {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .TravelInvalidID img {
    width: 327px;
    height: 266px;
    object-fit: contain;
  }
  .header{
  font-size: 22px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0.005em;
  text-align: center;
  margin-top: 40px;
  }
  .body{
    font-size: 18px;
    color: #A1A4B1;
    width: 311px;
    text-align: center;
  }
  .btn{
    margin-top: 30px;
    padding: 16px 108px ;
    font-size: 16px;
    border-radius: 10px;
  }