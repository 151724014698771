
.LUP-form {
  display: flex;
  flex-direction: row;
  width: 433px;
}
.LUP-input {
  width: 100%;
  height: 56.6px;
  border-radius: 4px 0px 0px 4px;
  box-sizing: border-box;
  outline: none;
  --webkit-transition: 0.5s;
  padding: 12px;
  font-size: 15px;
  border: 1px solid #f0f0f0;
}
.LUP-input:focus {
  border: 2px solid #34a853;
}
.LUP-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.LUP-button{
    width: 15%;
    border-radius: 0px 4px 4px 0px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #f0f0f0;
    background: transparent;
}
.LUP-button-icon{
    width: 25px;
height: 25px;
color:  #676767;
}