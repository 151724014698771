.momo-header {
  height: 59px;
  display: flex;
  flex-direction: row;
  padding: 10px 35px;
  background-color: white;
  width: 100%;
}
.momo-header-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.momo-header-img img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.momo-header-text {
  margin-left: 20px;
}
.checkoutInput-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position:absolute;
  padding: 0 35px;
  background-color: antiquewhite;

}
.checkoutInput-buttons a {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.icon {
  font-size: 20px;
}
.checkoutInput-buttons button {
  background: #10be4f;
  border-style: none;
  border-radius: 3px;
  width: 150px;
  height: 40px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: white;
}

/* ,,,,,,,,,,,,,,........ */
@media screen and (max-width: 576px) {
  .checkoutInput-buttons {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 25px;
  }
  .checkoutInput-buttons a {
    margin-top: 35px;
  }
}

@media screen and (max-width: 480px) {

}