
.prompt-notice-p1 {
  margin-top: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}
.prompt-notice-p2 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 12px 55px;
  text-align: center;

  color: #676767;
}
.prompt-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: underline;
}