
.card {
    display: flex;
    flex-direction: column;
  }
  .card-form-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 41px;
    margin-right: 41px;
  }
  .card-form-name-first {
    display: flex;
    flex-direction: column;
  }
  .card-form-input-label {
    display: flex;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
  }
  .card-form-input {
    display: flex;
    width: 185px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    padding: 14px;
    background: #ffffff;
    outline: none;
    border-radius: 4px;
    border-style: none;
    margin-top: 5px;
  }
  .card-form-input:focus {
    border: 2px solid #34a853;
  }
  .card-form-name-last {
    display: flex;
    flex-direction: column;
  }
  .card-form-phone {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 41px;
    margin-right: 41px;
  }
  .card-form-input1 {
    display: flex;
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    padding: 14px;
    background: #ffffff;
    outline: none;
    border-radius: 4px;
    border-style: none;
    margin-top: 5px;
  }
  .card-form-input1:focus {
    border: 2px solid #34a853;
  }
  .cap {
    display: flex;
    width: 178px;
    height: 46px;
    border: 1px solid #a1a1a1;
    background: #a1a1a1;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 41px;
    margin-right: 41px;
  }
  