.premium {
  width: 100%;
  display: flex;
  justify-content: center; 
}
.premium-card{
    margin: 20px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #fbfafa;
    width: 345px;
}
.premium h5{
    color: #000;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 10px;
}
.premium h4{
    color: #000;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 30px;
}
.premium-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 17px;
}
.premium-col1{
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.premium-col2{
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}