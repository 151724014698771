
.LUPACD {
  width: 100%;
}
.LUPACD-container {
  width: 100%;
  height: auto;
  padding: 0px 30px 30px 30px;
  background: #f9f9f9;
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 20px;
}
.LUPACD-container-fold {
  position: relative;
  display: flex;
  flex-direction: row;
}
.LUPACD-arrow {
  position: absolute;
  right: -15px;
  bottom: -23px;
}
.LUPACD-fold-item {
  margin: 15px 0;
}
.LUPACD-fold-item1 {
  margin: 15px 0px 0px 45px;
}
