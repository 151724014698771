
.main {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-top: 60px;
  margin-bottom: 46px;
}
.main-content-logo {
  width: 85px;
  height: 85px;
  border-radius: 12px;
}
.main-content-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.main-content-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
}
.main-content-text-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #000000;
}
.main-content-text-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 8px;

  color: #000000;
}

.main-loader{
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
}


/* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */

