.checkbocPosition{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: flex-start !important;
    align-items: center;
}
.checkbocPosition label::after{
display: none;
font-size: 1px;
}
.checkbocPosition label{
font-size: 13px;
}
.checkbox{
    width: 347px;
    display: flex;
    flex-direction: column;
    justify-content:start;
    margin-bottom: 5px;
}
.checkboxInput {
    display: flex;
    flex-direction: column;

  }
.checkboxInput label {
    margin-bottom: 5px;
    color: #8f92a1;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.065px;
  }
.checkboxInput label::after{
display: none;
  }
.checkboxStyle{
    margin-right: 8px;
}
.datePickersPosition2 button{
  width: 60px;
  height: 45px;
  margin-right: 10px;
  border: 1px solid #b7bfcb;
  border-radius: 4px;
  background: transparent;
  font-size: 13px;
  margin-bottom: 10px;
}
.checkboxToBuyForSomeone{
  width: 347px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: flex-start !important;
  align-items: center;
}
.checkboxToBuyForSomeone label::after{
  display: none;
  font-size: 1px;
  }
input[type="checkbox"]:checked {
    --checkbox-color: #40ad52;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #b7bfcb;
    border-radius: 4px;
    outline: none;
  }
  
  input[type="checkbox"]:checked {
    background-color: var(--checkbox-color);
  }
  input[type="checkbox"]:checked::before {
    content: "\f00c"; 
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 2.5px;
  }
  @media only screen and (max-width: 350px) {
    .checkbox{
      width: 300px;
  }
}
