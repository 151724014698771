
h4 {
  font-size: 15px;
  line-height: 14px;
}
.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  z-index: -1;
}
.checkout-card {
  width: 878px;
  height: 624px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;

  margin-top: 50px;
}
.checkout-card-options {
  width: 522px;
  height: 624px;
  display: flex;
  flex-direction: column;
  border-radius: 8px 0 0 8px;
  align-items: center;
  background: #f5f5f5;
}
.checkout-card-options-wizard {
  margin-top: 40px;
  width: 100%; 
}
.checkout-card-options-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:30px;
  height: 74%;
  overflow: auto;
  scroll-behavior: smooth;
  /* background-color: antiquewhite; */
}

/* ..........................Summary list.............................. */

.checkout-card-summary {
  border-width: 0.3px 0.3px 0.3px 0px;
  border-style: solid;
  border-color: rgba(186, 186, 186, 0.5);
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex-direction: column;
}
.checkout-card-summary-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 40px;
}
.checkout-card-summary-items-header {
  margin-top: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}
.checkout-card-summary-items-content {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.checkout-card-summary-items-content-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.checkout-card-summary-items-content-img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.checkout-card-summary-items-content-info {
  margin-left: 8px;
  width: 169px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.checkout-card-summary-items-content-info-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #676767;
}
.checkout-card-summary-items-content-info-input {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  color: #000000;
}
.bold {
  font-weight: 800;
}
.line {
  width: 227px;
  height: 1px;
  border: 1px solid #e9e8e8;
  margin-top: 13px;
}

/* ..........................Summary Details.............................. */
.checkout-card-summary-charge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.checkout-card-summary-charge-fee {
  width: 206px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.checkout-card-summary-charge-vat {
  width: 206px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.checkout-card-summary-charge-total {
  width: 206px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 45px;
}
.checkout-card-summary-charge-button {
  width: 206px;
  height: 31px;
  border: 1px solid #34a853;
  border-radius: 3px;
  font-weight: 700;
  line-height: 16px;
  background-color: transparent;
  cursor: pointer;
}
.checkout-card-summary-charge-button:hover {
  background-color: #34a853;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}
/* ..........small screen............ */
.checkout-card-summary-smallscreen{
  display: none;
  border-width: 0.3px ;
  align-items: center;
  border-style: solid;
  border-color: rgba(186, 186, 186, 0.5);
  border-radius:  8px ;
  flex-direction: column;
  position: relative;
}
.arrow-down{
position: absolute;
right: 25px;
top: 30px;
}
.ss-total{
  margin-top: 20px;
}
.ss-button{
  margin-bottom: 20px;
}
.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* .......Mobile...... */

@media screen and (max-width: 795px) {
  .checkout-card {
    width: 482px;
    height: auto;
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .checkout-card-options {
    width: 482px;
    height: 570px;
    margin-top: 25px;
    margin-bottom:30px ;
  }
  .checkout-card-options-wizard {
    margin-top: 0;
    margin-bottom: 25px;
    width: 100%;
    background-color: white;
  }

  .checkout-card-summary {
    border-width: 0.3px 0.3px 0.3px 0.3px;
    border-radius: 6px 6px 6px 6px;
    height: 433px;
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
  .checkout-card-summary-charge {
    margin-bottom: 15px;
  }
  .checkout-card-summary {
    display: none;
  }
  .checkout-card-summary-smallscreen{
    display: flex;
  }
}
@media screen and (max-width: 576px) {
  .checkout-card-options {
    width: 482px;
    height: max-content;
    margin-top: 25px;
  }
  .checkout-card-options-list{
    margin: 50px 0;
  }
  .checkout-card-options-wizard{
    display: none;
  }

}

@media screen and (max-width: 480px) {
  .checkout-card-options {
    width: 482px;
    height: max-content;
    margin-top: 0px;
  }
  .checkout-card {
    width: 400px;
    height: auto;
    margin-top: 50px;
  }
  .checkout-card-options {
    width: 400px;
  }
  .checkout-card-summary-smallscreen{
    margin-bottom: 25px; 
   }
}
