.m-m{
  width: 100%;
  position: relative;
}
.mobilemoney {
  display: flex;
  flex-direction: column;
  width: 522px;
}
.momo {
  display: flex;
  flex-direction: column;
}
.momo-form-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  margin-left: 41px;
  margin-right: 41px;
}
.momo-form-name-first {
  display: flex;
  flex-direction: column;
}
.momo-form-input-label {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}
.momo-form-input {
  display: flex;
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  padding: 14px;
  background: #ffffff;
  outline: none;
  border-radius: 4px;
  border-style: none;
  margin-top: 5px;
}
.momo-form-input:focus {
  border: 2px solid #34a853;
}
.momo-form-name-last {
  display: flex;
  flex-direction: column;
}
.momo-form-phone {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 41px;
  margin-right: 41px;
}
.momo-form-input1 {
  display: flex;
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  padding: 14px;
  background: #ffffff;
  outline: none;
  border-radius: 4px;
  border-style: none;
  margin-top: 5px;
}
.momo-form-input1:focus {
  border: 2px solid #34a853;
}
.cap {
  display: flex;
  width: 178px;
  height: 46px;
  border: 1px solid #a1a1a1;
  background: #a1a1a1;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 41px;
  margin-right: 41px;
}

/* ,,,,,,,,,,,,,,........ */
@media screen and (max-width: 576px) {
  .centerfield {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 35px;
    margin-bottom: 10px;
  }
  .cap {
    margin: 0;
  }
}
@media screen and (max-width: 480px) {
  .momo-form-name {
    flex-direction: column;
  }
  .mobilemoney {
    width: 422px;
  }
  .momo-form-phone {
    margin-top: 20px;
  }
  .momo-form-name-last{
    margin-top: 20px;

  }
}
