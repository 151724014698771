
.payflow-form {
  display: flex;
  flex-direction: column;
  width: 433px;
}
.payflow-form-input {
  width: 100%;
  height: 56.6px;
  margin-top: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  --webkit-transition: 0.5s;
  padding: 12px;
  font-size: 15px;
  border: 1px solid #f0f0f0;
}

.payflow-form-input:focus {
  border: 2px solid #34a853;
}

.payflow-form-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.payflow-form-total {
  font-weight: 900;
  font-size: 25px;
  line-height: 19px;
  text-align: start;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #000000;
}
.payflow-form-button {
  width: 100%;
  height: 56.67px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #34a853;
  border-radius: 7px;
  border-style: none;
  cursor: pointer;
margin-bottom: 20px;
  color: #ffffff;
}
.payflow-form-button:hover {
  color: #ffffff;
}

/* .......................... */

@media screen and (max-width: 460px) {
  .payflow-form {
    width: 333px;
  }
}
