.collapse-section {
  margin-bottom: 10px;
  width: 347px;
}

.collapse-header {
  padding: 10px 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between; /* Aligns the arrow to the right */
  align-items: center;
}
.collapse-header .arrow {
  font-size: 20px;
  margin-right: 10px;
}
.textareaStyle {
  width: 347px;
  border-radius: 6px;
  padding: 10px;
  background: transparent;
  border: 1px solid #b7bfcb;
  color: black;
  font-size: 13px;
}
.notRequired label::after{
    display: none;
}
.checkbox label::after{
display: none;
}
.checkbox-label{
    margin-bottom: 5px;
    color: #8f92a1;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.065px;
    margin-bottom:20px ;
}
