
.Onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f2f4f7;
}
.Onboarding h3 {
  font-size: 20px;
  margin-bottom: 40px;
}
.Onboarding button {
  border-radius: 10px;
  background: #40ad52;
  display: flex;
  width: 168px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  gap: 10px;
  flex-shrink: 0;
  border-style: none;
  margin-top:30px ;
  color: #ffffff;
}
.policyType {
  background-color: #ffffff;
  width: 284px;
  height: 229px;
  flex-shrink: 0;
  border-radius: 21px;
  margin: 20px;
  border-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.policyType-img img {
  width: 174px;
  height: 81px;
  object-fit: contain;
}
@media only screen and (max-width: 320px) {
    .policyType {
        background-color: #ffffff;
        width: 234px;
        height: 179px;
        flex-shrink: 0;
        border-radius: 21px;
        margin: 10px;
        border-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .Onboarding button {
        width: 168px;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.08px;
        gap: 10px;
        flex-shrink: 0;
        border-style: none;
        margin-top:25px ;
        color: #ffffff;
      }
  }