@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
body{
    font-family: 'Roboto', sans-serif;
}
.font1{
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
}
.font2{
    color: #676767;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}
label:after {
    content: "*";
    color: red;
    font-weight: bold;
    margin-left: 5px;
  }
  