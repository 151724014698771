
.verify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.verify-notice {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verify-notice-icon {
  width: 54px;
  height: 68px;
}
.verify-notice-icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.verify-notice-p1{
    margin-top: 30px;
    font-weight: 600;
font-size: 16px;
line-height: 19px;
margin-left:124px;
margin-right:124px;
text-align: center;
}
.verify-notice-p2{
    font-weight: 400;
font-size: 13px;
line-height: 20px;
margin: 15px 65px;
text-align: center;

color: #676767;
}
.verify-button button {
  background: #10be4f;
  border-style: none;
  border-radius: 3px;
  width: 172px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: white;
  margin-top: 18px;
  cursor: pointer;
}
.verify-spin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 480px) {
.verify-button button {
margin-bottom: 50px;
}
}