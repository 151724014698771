.done {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .done-image {
      width: 49px;
      height: 49px;
      margin-top: 34px;
  }
  .done-image img{
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  .done-text{
      text-align: center;
      margin-top: 15px;
  }
  .done-table{
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      width: 277px;
  }
  .done-table-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 17px;
  }
  
  /* .font1{
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
  } */
  .font3{
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
  }
  /* .font2{
      color: #676767;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
  } */