
.kowri-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 75px;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.04);
}
.kowri-navbar-img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
 
}
.kowri-navbar-img span {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.07px;;
}
.kowri-navbar-img img {
  width: 116px;
  height: 36px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .kowri-navbar {
    justify-content: center;
    box-shadow: none;
  }
  .kowri-navbar-img {
    width: 116px;
  }
  .kowri-navbar-img img {
    width: 84px;
    height: 25px;
    object-fit: cover;
  }
}
