.wizard {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.wizardComponent {
  width: 311px;
  height: 39px;
  position: relative;
}

.TravelDetails,
.Premium2,
.CustomerDetails,
.Payment {
  position: absolute;
  text-align: center;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.06px;
  word-wrap: break-word;
}

.TravelDetails {
  left: 0;
  top: 22px;
}

.CustomerDetails {
  left: 160px;
  top: 21px;
}

.Premium2 {
  left: 90px;
  top: 21px;
}

.Payment {
  left: 266px;
  top: 22px;
}

.Rectangle1 {
  position: absolute;
  height: 1px;
  width: 76px;
  left: 33px;
  top: 5px;
  background: #d9d9d9;
}

.Rectangle2 {
  position: absolute;
  height: 1px;
  width: 76px;
  left: 121px;
  top: 5px;
  background: #d9d9d9;
}

.Rectangle3 {
  position: absolute;
  height: 1px;
  width: 76px;
  left: 207px;
  top: 5px;
  background: #d9d9d9;
}

.Ellipse {
  width: 273px;
  height: 12px;
  left: 22px;
  top: 0;
  position: absolute;
}

.EllipseCustomerDetails{
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 87px;
  top: 0;
  background-color: #f2f4f7;
}

.EllipsePremium {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 174px;
  top: 0;
  background-color: #f2f4f7;
}

.EllipsePayment {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 261px;
  top: 0;
  background-color: #f2f4f7;
}

.EllipseTravelDetails {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 0;
  top: 0;
  background-color: #f2f4f7;
}

.TravelInsurance {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffff;
}
.TravelInsuranceTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
}
