
.cardsheet {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 75px;
}
.cardsheet-card {
  width: 838px;
  height: 584px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  animation: transformIn .8s ease-in-out forwards;
}
@keyframes transformIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
.cardsheet-card-form {
  width: 482px;
  background: #f9f9f9;
}

.cardsheet-form-phone {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 41px;
  margin-right: 41px;
}
.cardsheet-form-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 41px;
  margin-right: 41px;
}
.cardsheet-back {
  display: flex;
  width: max-content;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  text-decoration: underline;
  margin-top: 30px;
  margin-left: 41px;
  margin-right: 41px;
  cursor: pointer;
}

/* ..........summary............... */
.cardsheet-summary {
  background-color: white;
  padding: 0 40px;
  border-width: 0.3px 0.3px 0.3px 0px;
  border-style: solid;
  border-color: rgba(186, 186, 186, 0.5);
  border-radius: 0px 6px 6px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardsheet-button {
  background: #10be4f;
  border-style: none;
  border-radius: 3px;
  width: 150px;
  height: 40px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}
.cardsheet-card-summary-items-header {
  margin-top: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}
.cardsheet-card-summary-items-content-1 {
  display: flex;
  flex-direction: row;
  width: 240px;
  justify-content: space-between;
  margin-top: 20px;
}
.cardsheet-ad {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.cardsheet-image {
  border: 1px solid green;
  width: 240px;
  height: 300px;
}
.cardsheet-image img {
  width: 100%;
}
