
.kowri-home {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url(../../images/background.png);
}
.kowri-home-img {
  width: 123px;
  height: 46px;
}
.kowri-home-img img {
  width: 100%;
  height: 100%;
}

.kowri-home-img {
  animation: roll-in-left 1.5s ease-out both;
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
