
.payoption {
  display: flex;
  flex-direction: column;
  width: 433px;
}
.payoption-form-input {
  width: 100%;
  height: 56.6px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  --webkit-transition: 0.5s;
  padding: 12px;
  font-size: 15px;
  border: 1px solid #f0f0f0;
}
.payoption-form-input:focus {
  border: 2px solid #34a853;
}
.payoption-form-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.payoption-scroll {
  width: 100%;
  height: 300px;
  overflow-y: auto;

  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-top: 20px;
}

.payoption-item{
    display: flex;
    flex-direction: column;
    margin: 20px 35px;
}
.payoption-list{
    display: flex;
}
.payoption-list-data{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.payoption-line{
    width: 100%;
    height: 1px;
    border: 1px solid #e9e8e8;
    margin-top: 13px;
}
.payoption-list-data-name{
    margin-left: 15px;
}

.payoption-scroll::-webkit-scrollbar {
  width: 11px;
}

.payoption-scroll::-webkit-scrollbar-track {
  background: #F0F0F0;
  border-radius: 100vw;
}

.payoption-scroll::-webkit-scrollbar-thumb {
  background:#C1C1C1;
  border-radius: 100vw;
}

.payoption-scroll::-webkit-scrollbar-thumb:hover {
  background: hsl(120 100% 10% / 1);
}
