.MotorInsurance {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffff;
}
.MotorInsuranceTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 10px;
}
.MotorInsuranceTitle h3 {
  font-size: 20px;
}

.form {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formStyle {
  width: 347px;
  height: 46px;
  border-radius: 6px;
  padding: 10px;
  background: transparent;
  border:1px solid #b7bfcb;
  color: black;
  font-size: 13px;
}
.formPosition {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.formPosition label {
  margin-bottom: 5px;
  color: #8f92a1;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.065px;
}
.datePickersPosition2 label {
  margin-bottom: 5px;
  color: #8f92a1;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.065px;
}

.datePickersPosition {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 347px;
}
.datePickersPosition2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 347px;
}
.engineCapacity{
  display: flex;
}
.datePickersStyle {
  width: 165px;
  height: 46px;
  padding: 10px;
  color: black;
  font-size: 13px;
  border-radius: 6px;
  background: transparent;
  border:1px solid #b7bfcb;
}
.datePickersStyle2 {
  width: 200px;
  height: 46px;
  padding: 10px;
  color: black;
  font-size: 13px;
  border-radius: 6px;
  background: transparent;
  border:1px solid #b7bfcb;
}
.button-green {
  padding: 16px 50px;
  height: 50px;
  border-radius: 10px;
  background: #40ad52;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.08px;
  gap: 10px;
  flex-shrink: 0;
  border-style: none;
  margin-top:30px ;
  margin-bottom: 50px;
  color: #ffffff;

}
.button-gray {
  padding: 16px 50px;
  height: 50px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #B7BFCB;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.08px;
  gap: 10px;
  flex-shrink: 0;
  margin-top:30px ;
  margin-bottom: 50px;
  color: #000000;

}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.button-double{
  display: flex;
  width: 320px;
  justify-content: space-around;
}
.selectContainer {
  position: relative;
  display: inline-block;
}

.selectIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}
.datePickerContainer {
  position: relative;
}

.datePickerIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}
.MotorInsuranceerror {
  color: red;
  font-size: 10px;
  margin-top: 5px;
}

@media only screen and (max-width: 350px) {
    .formStyle {
        width: 300px;
        height: 46px;
      }
      .datePickersPosition {
        width: 300px;
      }
      .datePickersStyle {
        width: 135px;
        height: 46px;

      }
}


.wizard{
  width:100%;
  display:flex;
  justify-content:center;
  margin-top:50px;
}

.wizardComponent {
  width: 311px;
  height: 39px;
  position: relative;
}

.CarDetails, .Premium, .Policy, .Payment {
  position: absolute;
  text-align: center;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.80px;
  letter-spacing: 0.06px;
  word-wrap: break-word;
}

.CarDetails {
  left: 10px;
  top: 22px;
}

.Premium {
  left: 169px;
  top: 21px;
}

.Policy {
  left: 88px;
  top: 21px;
}

.Payment {
  left: 266px;
  top: 22px;
}


.Rectangle1 {
    position: absolute;
  height: 1px;
  width: 76px;
  left: 33px;
  top: 5px;
  background: #D9D9D9;
}

.Rectangle2 {
    position: absolute;
  height: 1px;
  width: 76px;
  left: 121px;
  top: 5px;
  background: #D9D9D9;
}

.Rectangle3 {
    position: absolute;
  height: 1px;
  width: 76px;
  left: 207px;
  top: 5px;
  background: #D9D9D9;
}

.Ellipse {
  width: 273px;
  height: 12px;
  left: 22px;
  top: 0;
  position: absolute;
}


.EllipsePolicy {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 87px;
  top: 0;
  background-color: #f2f4f7;

}

.EllipsePremium {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 174px;
  top: 0;
  background-color: #f2f4f7;

}

.EllipsePayment {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 261px;
  top: 0;
  background-color: #f2f4f7;
}

.EllipseCarDetails {
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  left: 0;
  top: 0;
  background-color: #f2f4f7;
}
