/* Add the following styles in your CSS file or a style block */

.language-selector {
    position: absolute;
    display: inline-block;
     top: 10px;
     right: 40px;
     z-index: 1;
  }
  
  .icon-container {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index:1;
  }
  
  .language-dropdown {
    padding: 8px 40px 8px 12px;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    outline: none;

    border-radius: 4px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  
  .language-dropdown option {
    padding: 4px;
  }
  .text-large{
    display: block;
  }
  .text-short{
    display: none;
  }
  @media only screen and (max-width: 425px) {
 .language-selector {
    position: absolute;
    display: inline-block;
     z-index: 1;
     top: 10px;
     right: 20px;
  }
    .language-dropdown {
    padding: 6px 8px 6px 8px;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    outline: none;

    border-radius: 4px;
    font-size: 12px;
    color: #333;
    cursor: pointer;
  }
   .icon-container {
     display: none;
    }
    .text-large{
      display: none;
    }
    .text-short{
      display: block;
    }
  }