.TravelComingSoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.TravelComingSoon img {
  width: 327px;
  height: 266px;
  object-fit: contain;
}
.TravelComingSoon p{
font-size: 22px;
font-weight: 500;
line-height: 41px;
letter-spacing: 0.005em;
text-align: center;
margin-top: 40px;
}