
.modal {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-container {
  width: 474px;
  height: 190px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  border-radius: 4px;
  top: 269px;
}
.modal-container-close {
  text-align: right;
  padding: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.modal-container-header {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.modal-container-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: right;

}
.modal-cancel-no {
  width: 90px;
  height: 40px;
  background: #10be4f;
  border-radius: 3px;
  border: none;
  color: white;  font-weight: 400;
font-size: 18px;
margin-right: 15px;
cursor: pointer;
}
.modal-cancel-yes {
  width: 90px;
  height: 40px;
  background: transparent;
color: #10be4f;
  border: 0.5px solid #10be4f;
  border-radius: 3px;
  font-weight: 400;
  font-size: 18px;
  margin-right: 25px;
  cursor: pointer;
}
