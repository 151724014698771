.Vin {
  padding: 0px 20px;
  margin-top: 80px;
}
.vinError {
  margin-top: 5px;
  color: red;
}
.VinText {
  padding: 0px 40px;
}
.VinText h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 10px;
}
.VinText p {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.005em;
  text-align: center;
  color: #8f92a1;
}
.VinImage {
  padding: 80px 20px;
}
.VinImg {
  width: 100%;
  height: 194px;
  object-fit: contain;
}
.vinBtn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #40ad52;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.08px;
  gap: 10px;
  flex-shrink: 0;
  border-style: none;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #ffffff;
}
.skipBtn{
  display: flex;
  justify-content: end;
}
.skipBtn button{
  background: transparent;
  border: none;
  font-size: 12px;
  color: #8f92a1;
  padding: 10px;
}
