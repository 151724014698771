.PayPremium{
    width: 100%;
    display: flex;
    justify-content: center; 
}
.PayPremium-card{
    margin: 20px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #fbfafa;
    width: 345px;
    position: relative;
}
.PayPremium-card h5{
    color: #40AD52;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
}
.PayPremium-card h6{
    color: #9C9EAC;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    margin-bottom: 30px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    max-width: 400px;
    text-align: center;
  }
  
  .modal-content h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
    color: red;
  }
  
  .modal-content p {
    margin-bottom: 20px;
    font-size: 13px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    border-radius: 4px;
    width: 100px;
    cursor: pointer;
  }
  

.button-modal-green{
  margin-right: 20px;
  background-color: #4fa94d;
  border:none;
  color:white;
}
.button-modal-gray{
  width: 100px;
  background: transparent;
  border: 1px solid #B7BFCB;
  color: black;
}
.collaps-btn{
  position: absolute;
  bottom: -15px;
  right: 20px;
}
.collaps-btn button{
  border: none;
  background: transparent;
  font-size: 25px;
  color: #B7BFCB;
}

@media only screen and (max-width: 400px) {
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    max-width: 350px;
    text-align: center;
  }
}
.premium-col1-copy{
  display: flex;
}
.Copy{
  color: #40AD52;
  margin-left:5px ;
}