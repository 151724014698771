
.paymentOptions {
  width: 420px;
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: none;
  background-color: white;
  margin-top: 15px ;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
}
.paymentOptions:hover{
  border: 1px solid #34a853;
  border-radius: 4px;
}
.paymentOptions-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.paymentOptions-img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.paymentOptions-text {
  height: 40px;
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.font2{
  margin-top: 5px;
}


/* mm,,,,,,,,,,,,,,,,,,,,,,,, */
@media screen and (max-width: 480px) {
  .paymentOptions {
    width: 370px;
    height: 69px;
  }
}